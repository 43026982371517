.legoContainer {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 25px;
    flex-wrap: wrap;
}

.actionButtonContainer {
    display: flex;
    justify-content: space-around;
}