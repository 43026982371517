iframe {
    width: 100%;
    height: 40vh;
}

.contacts {
    height: 100%;
}

.link {
    color: rgba(0, 0, 0, 0.6);
}